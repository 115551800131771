
@import '../../../../estee_base/scss/theme-bootstrap';
.page-footer {
  .email-signup {
    .email_signup {
      &__header {
        display: none;
        @media #{$medium-up} {
          display: block;
        }
      }
    }
    input[type='tel'] {
      margin: 15px 0;
      font-size: 12px;
      @media #{$medium-up} {
        margin: unset;
        font-size: 15px;
      }
    }
    .field.mobile-sms,
    .field.mobile-email {
      position: relative;
      input.promotions {
        width: 22px;
        height: 20px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        position: absolute;
        #{$ldirection}: -3px;
        top: 2px;
        z-index: 5;
      }
    }
    .error_messages_display {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .email-signup-email {
      width: 49%;
      display: inline-block;
      .signup-promotions {
        float: #{$ldirection};
        padding-top: 0;
        margin-top: 10px;
        cursor: unset;
      }
      .pc-email {
        position: relative;
        input.promotions {
          width: 22px;
          height: 20px;
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          position: absolute;
          #{$ldirection}: -3px;
          top: 22px;
          z-index: 5;
        }
      }
    }
    .email-signup-mobile {
      width: 49%;
      display: inline-block;
      vertical-align: top;
      .signup-promotions {
        float: #{$ldirection};
        padding-top: 0;
        margin-top: 10px;
        cursor: unset;
      }
      .form-item.sms-promotions {
        position: relative;
        input.promotions {
          width: 22px;
          height: 20px;
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          position: absolute;
          #{$ldirection}: -3px;
          top: 22px;
          z-index: 3;
        }
      }
    }
    &__section {
      width: 100% !important;
    }
  }
}

.nodeblock-signup-form {
  .error_messages_display {
    font-size: 12px;
    margin-bottom: 20px;
    overflow: hidden;
    @media #{$medium-up} {
      font-size: 11px;
      margin-bottom: 10px;
    }
  }
  .email-signup-email {
    margin-bottom: 20px;
    .form-item {
      margin-bottom: 10px;
    }
  }
  .email-signup-mobile {
    .form-item {
      margin-bottom: 20px;
    }
  }
  input[name='PHONE2'] {
    width: 100%;
    margin: 15px 0;
    @media #{$medium-up} {
      margin: unset;
    }
  }
  .mobile-sms {
    margin-bottom: 20px;
  }
}